import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClientService } from './http-client.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private httpClientService: HttpClientService
  ) {
    this.userSubject = new BehaviorSubject<any>(null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  login(email: string, password: string) {
    let credential = {
      email,
      password,
    };
    const resource = `auth/login`;

    return this.httpClientService
      .post(resource, credential, { withCredentials: true })
      .pipe(
        map((response) => {
          const { data } = response;
          // this.startRefreshTokenTimer();
          console.log(data?.tokens?.access?.token);
          const accessToken = data?.tokens?.access?.token;
          const refreshToken = data?.tokens?.refresh?.token;
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('user', JSON.stringify(data?.user));

          return data.user;
        })
      );
  }

  logout() {
    const resource = `auth/logout`;
    const body = {
      refreshToken: localStorage.getItem('refreshToken') ?? null,
    };
    localStorage.removeItem('user');
    return this.httpClientService.post(resource, body);
  }

  loginToken(token: any) {
    const resource = `auth/login-by-tokens`;
    const body = { token };
    const existToken = localStorage.getItem('pairingToken');
    if (existToken == token) {
      return;
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('pairingToken');
    }

    return this.httpClientService.post(resource, body).pipe(
      map((response) => {
        const { data } = response;
        // this.startRefreshTokenTimer();
        console.log(data?.tokens?.access?.token);
        const accessToken = data?.tokens?.access?.token;
        const refreshToken = data?.tokens?.refresh?.token;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('user', JSON.stringify(data?.user));
        localStorage.setItem('pairingToken', token);
        return data.user;
      })
    );
  }
}
