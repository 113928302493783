import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmOption } from '../../data.const';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  data?: any;
  dialogInfo?: any;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.data = config?.data?.dataItem;
    this.dialogInfo = config?.data?.dialogInfo;
  }
  ngOnInit(): void {}

  handleClose() {
    this.ref.close(ConfirmOption.canceled);
  }
  handleConfirmed() {
    this.ref.close(ConfirmOption.confirmed);
  }
}
