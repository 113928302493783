import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoginFormService } from '../services/login-form.service';
import { AuthenticationService } from 'src/app/share/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  isShow: boolean = false;
  username: string = '';
  password: string = '';

  loading: boolean = false;

  constructor(
    private loginFormService: LoginFormService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    // private route: ActivatedRoute
  ) {
    // const token = this.route.snapshot.queryParamMap.get("pairing-token");
    // this.authenticationService.loginToken(token)?.subscribe({
    //   next: (response: any) => {
    //     console.log(response);
    //     const { name } = response;
    //     this.toastr.success(`Welcome back, ${name}!`, 'Success');
    //     location.reload();
    //   }
    // })
  }

  ngOnInit() {
    this.loginFormService.visible.subscribe((value: any) => {
      this.isShow = value;
    });
  }

  load() {
    this.loading = true;
    this.onSubmitCredential();
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  onSubmitCredential() {
    console.log(this.password);
    this.authenticationService.login(this.username, this.password).subscribe({
      next: (response) => {
        const { name } = response;
        this.toastr.success(`Welcome back, ${name}!`, 'Success');
        this.loginFormService.hideLogin();
        location.reload();
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        console.log('ok');
      },
    });
  }
}
