<div *ngIf="isAccessDenied" class="w-screen h-screen">
  <img
    class="w-full h-full relative"
    src="../../../../assets/images/svgs/403.svg"
    alt="access denied"
  />
  <!-- <div
    (click)="handleLogOut()"
    class="text-blue-500 underline absolute top-[52%] left-[8%] 2xl:left-[10%] 2xl:top-[58%] lg:top-[55%] md:top-[55%] xl:top-[57%] cursor-pointer text-xs xl:text-base 2xl:text-lg"
  >
    Login with another account
  </div> -->
</div>
