import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { LoginFormService } from './share/components/login-form/services/login-form.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'TEC';
  isAccessDenied = false;

  constructor(
    private titleService: Title,
    private loginFormService: LoginFormService
  ) {
    const currentState = environment.state;
    const currentVersion = environment.version;
    let title = `${this.title}-${currentState}-${currentVersion}`;
    this.titleService.setTitle(title);
    this.loginFormService.accessDenied.subscribe((value: any) => {
      this.isAccessDenied = value;
    });
  }
}
