import { Component, OnInit } from '@angular/core';
import { LoginFormService } from '../login-form/services/login-form.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/features/master-data/components/confirm-dialog/confirm-dialog.component';
import { ConfirmOption } from 'src/app/features/master-data/data.const';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-access-denied-page',
  templateUrl: './access-denied-page.component.html',
  styleUrls: ['./access-denied-page.component.scss'],
  providers: [DialogService],
})
export class AccessDeniedPageComponent implements OnInit {
  isAccessDenied = false;
  ref!: DynamicDialogRef;

  constructor(
    private loginFormService: LoginFormService,
    private dialogService: DialogService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.loginFormService.accessDenied.subscribe((value: any) => {
      this.isAccessDenied = value;
    });
  }

  handleLogOut() {
    let dialogInfo = {
      primaryButton: 'Yes',
      secondButton: 'No',
      message: 'Make sure you want to logout?',
      title: 'Logout',
    };
    this.ref = this.dialogService.open(ConfirmDialogComponent, {
      width: '25%',
      data: { dialogInfo: dialogInfo },
      contentStyle: { padding: 0, 'border-radius': '10px' },
      closable: false,
      showHeader: false,
      dismissableMask: true,
    });
    this.ref.onClose.subscribe((data: any) => {
      if (data === ConfirmOption.confirmed) {
        this.authenticationService.logout().subscribe({
          next: (value: any) => {
            this.toastr.success(value?.data, 'Logout');
          },
          error: (err: any) => {
            this.toastr.error('Logout failed', 'Logout', {
              timeOut: 120000,
              progressBar: false,
            });
          },
          complete: () => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            location.reload();
          },
        });
      }
    });
  }
}
