<div class="w-full h-full p-6">
  <div class="w-full flex flex-col gap-6">
    <div class="w-full flex flex-row gap-3">
      <div
        class="w-12 h-10 rounded-full flex items-center justify-center shadow-2xl mt-2 {{
          data?.activate
            ? 'text-red-500 bg-red-100 rounded-full'
            : 'text-green-500 bg-green-100 rounded-full'
        }}"
      >
        <span
          class="material-icons-outlined {{
            data?.activate ? 'text-red-500 ' : 'text-green-500'
          }}"
        >
          check_circle
        </span>
      </div>
      <div class="flex flex-col items-center justify-start w-full">
        <div
          class="w-full text-lg font-semibold text-gray-600 flex items-center justify-start"
        >
          {{ dialogInfo?.title }}
        </div>
        <div class="w-full text-sm text-gray-600">
          {{ dialogInfo?.message }}
        </div>
      </div>
    </div>
    <div class="flex items-center gap-4 justify-center">
      <button
        (click)="handleClose()"
        class="px-7 py-1 rounded-md border border-gray-500 text-gray-600 font-medium"
      >
        {{ dialogInfo?.secondButton }}
      </button>
      <button
        (click)="handleConfirmed()"
        class="px-6 py-1 rounded-md border text-white  {{
          data?.activate
            ? 'bg-red-500 border-red-500'
            : 'bg-blue-500 border-blue-500'
        }}"
      >
        {{ dialogInfo?.primaryButton }}
      </button>
    </div>
  </div>
</div>
