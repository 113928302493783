// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  state: 'DEV',
  version: '1.15.1-SNAPSHOT-301023',
  production: false,
  apiUrl: 'https://api.cohr-tec-dev.tma-swerp.com/cohr-tec-service/v1/api/',
  urlImage: 'https://api.cohr-tec-dev.tma-swerp.com/cohr-tec-service',
  token: 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJVU0VSLTAwMDEiLCJ1c2VybmFtZSI6ImFkbWluIiwiZW1haWwiOiJkdW9uZ2JhdGluaDg5QGdtYWlsLmNvbSIsInR5cGUiOiJSRUZSRVNIIiwiaWF0IjoxNjg2Mjk0NjU2LCJleHAiOjE2ODg3MTM4NTZ9.NADTVusD3q9zbCEh8XFNmVtWL1HZ1WQ1alJorqgawu4',
};

