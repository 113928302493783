export const ConfirmOption = {
  canceled: 'CANCEL',
  confirmed: 'CONFIRM',
  download: 'DOWNLOAD',
};

export const OptionSelect = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const TypeCode = {
  variance: 'VARIANCE',
  calculate: 'CALCULATE',
  criteria: 'CRITERIA',
  matrix: 'MATRIX',
  number: 'NUMBER',
};

export const TypeExportTemplate = [
  {
    label: 'Input',
    value: 'INPUT',
  },
  {
    label: 'Output',
    value: 'OUTPUT',
  },
  {
    label: 'Criteria',
    value: 'CRITERIA',
  },
  {
    label: 'Update',
    value: 'UPDATE',
  },
];

export const Type = {
  object: 'OBJECT',
  string: 'STRING',
};
