<p-dialog
  header="Login"
  [(visible)]="isShow"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <section class="">
    <div class="container">
      <div
        class="g-6 flex flex-wrap items-center justify-center lg:justify-between"
      >
        <!-- Left column container with background-->
        <div class="mb-12 md:mb-0 md:w-8/12 lg:w-6/12">
          <img
            src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
            class="w-full"
            alt="Phone image"
          />
        </div>

        <!-- Right column container with form -->
        <div class="md:w-8/12 lg:ml-6 lg:w-5/12">
          <!-- <form> -->
            <div class="grid gap-7">
              <h3 class="font-semibold text-xl text-red-300">Please reload the page to gain access!</h3>
              <!-- Email input -->
              <!-- <span class="p-float-label">
                <input
                  pInputText
                  class="w-full"
                  id="username"
                  name="username"
                  [(ngModel)]="username"
                  [autocomplete]="'off'"
                  required
                />
                <label htmlFor="username">Username</label>
              </span> -->

              <!-- Password input -->
              <!-- <span class="p-float-label">

                <input
                  pInputText
                  class="w-full"
                  type="password"
                  id="password"
                  name="password"
                  [(ngModel)]="password"
                  [autocomplete]="'off'"
                  required
                />
                <label htmlFor="psw">Password</label>
              </span> -->

              <!-- Submit button -->
              <!-- <p-button [styleClass]="'w-full justify-center'" [loading]="loading" (click)="load()">
                Sign in
              </p-button> -->
            </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </section>
</p-dialog>
