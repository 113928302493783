import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClientService } from 'src/app/share/services/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class LoginFormService {
  visible: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  accessDenied: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
    this.visible = new BehaviorSubject<any>(false);
    this.accessDenied = new BehaviorSubject<any>(false);
  }

  showLogin() {
    this.visible.next(true);
  }

  showAccessDeniedPage() {
    this.accessDenied.next(true);
  }

  hideLogin() {
    this.visible.next(false);
  }
}
