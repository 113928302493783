import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  public BASIC_URL = 'http://localhost';
  constructor(private httpClient: HttpClient) {
    this.BASIC_URL = environment.apiUrl;
  }

  createParams(params?: any): HttpParams | undefined {
    if (params) {
      let httpParams: HttpParams = new HttpParams();
      for (const param in params) {
        httpParams = httpParams.append(param, params[param]);
      }
      return httpParams;
    }
    return undefined;
  }

  get(resource: string, data?: any): Observable<any> {
    const url = this.BASIC_URL + resource;
    let params: HttpParams | undefined = this.createParams(data);
    return this.httpClient.get<any>(url, { params });
  }

  post(resource: string, data: any, param?: any): Observable<any> {
    const url = this.BASIC_URL + resource;
    let params: HttpParams | undefined = this.createParams(param);
    return this.httpClient.post<any>(url, data, { params });
  }

  postFile(resource: string, file: any): Observable<any> {
    const url = this.BASIC_URL + resource;
    const formData = new FormData();
    formData.append("file", file);
    return this.httpClient.post<any>(url, formData);
  }

  postWithCredentials(resource: string, data: any, param?: any): Observable<any> {
    const url = this.BASIC_URL + resource;
    let params: HttpParams | undefined = this.createParams(param);
    return this.httpClient.post<any>(url, data, { params, withCredentials: true });
  }

  postDownFile(resource: string, data: any, param?: any): Observable<any> {
    const url = this.BASIC_URL + resource;
    let params: HttpParams | undefined = this.createParams(param);
    return this.httpClient.post<any>(url, data, { params, responseType: 'blob' as 'json' });
  }

  put(resource: string, data: any): Observable<any> {
    const url = this.BASIC_URL + resource;
    return this.httpClient.put<any>(url, data);
  }

  delete(resource: string, body?: any, param?: any): Observable<any> {
    const url = this.BASIC_URL + resource;
    let params: HttpParams | undefined = this.createParams(param);
    return this.httpClient.delete<any>(url, { body, params });
  }
}
