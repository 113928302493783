import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
// import { MessageService } from "primeng/api";
import { environment } from 'src/environments/environment';
import { LoginFormService } from 'src/app/share/components/login-form/services/login-form.service';
import { AuthenticationService } from 'src/app/share/services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private loginFormService: LoginFormService, private authenticationService: AuthenticationService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const hardToken = environment.token;
    const token = localStorage.getItem('accessToken') ?? null;
    const headers: HttpHeaders = request.headers.set(
      'Authorization',
      `Bearer ${token}`
    );
    const authReq = request.clone({ headers });
    return next.handle(authReq).pipe(
      map((event) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == HttpStatusCode.Unauthorized) {
          this.loginFormService.showLogin();
        }
        if (error.status == HttpStatusCode.Forbidden) {
          this.loginFormService.showAccessDeniedPage();
        }
        if (error.status == HttpStatusCode.BadRequest) {
          const url = request.url;
          if (url.includes('login-by-tokens')) {
            this.authenticationService.logout().subscribe({
              next: (value: any) => {
                console.log("Logout completed!");
              },
              error: (err: any) => {
                console.log(err);
              },
              complete: () => {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('pairingToken');
                location.reload();
              },
            });
          }

        }
        return throwError(() => error);
      })
    );
  }
}
